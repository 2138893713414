<template>
  <!--eslint-disable vue/camelcase-->
  <div class="mt-4">
    <h2>Contacts</h2>
    <div v-if="retrieving_table_data" class="text-center">
      <b-spinner small /> Retrieving Contacts
    </div>
    <div v-else>
      <div v-if="reviewContacts.length < 1">
        No Contacts
      </div>
      <b-table
          v-else
          :items="reviewContacts"
          :fields="requestContactsDatatable.columns"
          :sort-by.sync="requestContactsDatatable.queries.sort_by"
          :sort-desc.sync="requestContactsDatatable.queries.sort_desc"
          :per-page="requestContactsDatatable.queries.per_page"
          :no-local-sorting="true"
          :borderless="true"
          :striped="true"
      >
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <template v-slot:cell(review_request_series)="row">
          <table class="table table-bordered">
            <tr>
              <td>Series</td>
              <td>{{row.item.review_request_series.map(o => o.name).join(', ')}}</td>
            </tr>
          </table>
        </template>
        <template v-slot:cell(finished)="row">
          <span v-if="row.item.deleted_at || row.item.finished === 'true'">true</span>
          <span v-else>false</span>
        </template>
        <template v-slot:cell(id)="row" style="width:200px">
          <b-button size="sm" @click="row.item.modal_open = true">More Info</b-button>
          <b-modal v-model="row.item.modal_open" :hide-footer="true" :title="row.item.name">
            <table class="table table-bordered">
              <tr>
                <td>Series</td>
                <td>{{row.item.review_request_series.map(o => o.name).join(', ')}}</td>
              </tr>
              <tr>
                <td>Next Run Time</td>
                <td>
                  <span v-if="row.item.finished">Finished</span>
                  <span v-else-if="row.item.next_series_process_time_front_date !== null">
                    {{row.item.next_series_process_time_front_date}}
                  </span>
                  <span v-else>Pending</span>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-center bg-primary text-white">Visits</td>
              </tr>
              <tr>
                <td colspan="2" class="p-0">
                  <table v-if="row.item.visits && row.item.visits.length" class="table w-100 table-striped">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Step</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                    <tbody>
                      <tr v-for="visit in row.item.visits" :key="visit.id">
                        <td>{{visit.type}}</td>
                        <td>{{visit.step}}</td>
                        <td>{{visit.front_date}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="text-center p-2">None</div>
                </td>
              </tr>
            </table>
          </b-modal>
        </template>
        <template v-slot:cell(content)="row">
          <!-- eslint-disable-next-line-->
          <div v-html="row.item.nl2br_html"></div>
        </template>
      </b-table>
    </div>
    <div v-if="!retrieving_table_data && reviewContacts.length" class="row mt-4">
      <div class="col">
        <div class="float-left mt-2">
          Total {{ totalContacts }}
        </div>
        <div
            class="dataTables_paginate paging_simple_numbers float-right"
        >
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
                v-model="requestContactsDatatable.queries.page"
                :total-rows="totalContacts"
                :per-page="requestContactsDatatable.queries.per_page"
                @change="onReviewRequestLogPageChanged"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Vue from "vue";
export default {
  components:{
  },
  data(){
    return {
      user:null,
      retrieving_table_data:false,
      requestContactsDatatable: {
        items: [],
        columns: [
          { key: 'first_name', label:'First Name', sortable: true },
          { key: 'last_name', label:'Last Name', sortable: true},
          { key: 'email', label:'Email', sortable: true},
          { key: 'cell_phone', label:'Cell Phone', sortable: true},
          { key: 'total_steps', label:'Total Steps'},
          { key: 'current_step', label:'Current Step'},
          { key: 'finished', label:'Finished'},
          { key: 'front_date', label:'Created', sortable: true},
          { key: 'id', label:''},
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'created_at',
          sort_desc: false,
        },
      }
    }
  },
  computed:{
    reviewContacts(){
      return this.$store.getters['contactRequests/all'];
    },
    totalContacts(){
      return this.$store.getters['contactRequests/total'];
    },
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.getContacts();
  },
  methods:{
    getContacts(){
      this.retrieving_table_data = true;
      this.$store
          .dispatch(`contactRequests/getAll`, {
            queries: this.requestContactsDatatable.queries,
            page: 'contacts',
            businessId: this.user.business.id
          })
          .then((resp) => {
            console.log(resp);
            this.retrieving_table_data = false;
          })
          .catch((err) => {
            this.retrieving_table_data = false;
            console.error(err);
          })
    },
    onReviewRequestLogPageChanged(page) {
      this.requestContactsDatatable.queries.page = page
      this.getContacts();
    },
  }
}
</script>
